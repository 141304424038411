import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
// Types
import { SelectProps } from './type'

export default function SelectComponent({value, setValue, label, items, multiple, disabled}: SelectProps) {

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const val = event.target.value
    setValue(multiple && typeof val === 'string' ? val.split(',') : val || val)
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        {
          multiple &&
          <>
            <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={value}
              onChange={handleChange}
              input={<OutlinedInput label={label}/>}
              renderValue={(selected) => selected/* .join(', ') */}
              MenuProps={MenuProps}
              disabled={disabled}
            >
              {
                items.map(({value: val, label: lab}) => <MenuItem key={val} value={val}>
                  <Checkbox checked={value.indexOf(val) > -1} />
                  <ListItemText primary={lab} />
                </MenuItem>)
              }
            </Select>
          </> ||
          <>
            <InputLabel id="demo-simple-select-autowidth-label">{label}</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={value}
              onChange={handleChange}
              autoWidth
              label={label}
              disabled={disabled}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {items.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
            </Select>
          </>
        }
      </FormControl>
    </div>
  );
}
