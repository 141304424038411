import { ReactNode, useEffect, useMemo, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { useAuthContext } from 'src/auth/hooks'
import { useGetUsers } from 'src/api/user'
//
import WsContext from './ws-context'

const WS_URL = process.env.REACT_APP_WS_URL || ''
// const isUserEvent = (message: any) => JSON.parse(message.data).type === "user_event"
type Props = {children: ReactNode}
const WsProvider = ({children}: Props) => {
  const [wsConnected, setWsConnected] = useState(false)
  const { user, updateCurrentUser, logout } = useAuthContext()
  const { onRefreshRoles } = useGetUsers()
  const { sendJsonMessage, readyState } = useWebSocket(WS_URL, {
    onOpen: () => {
      console.log('WebSocket connection established.');
    },
    share: true,
    filter: () => false,
    retryOnError: true,
    shouldReconnect: () => true
  })
  const { lastJsonMessage } = useWebSocket<any>(WS_URL, {
    share: true,
    filter: () => true // isUserEvent
  })
  const [key, setKey] = useState(window.localStorage.getItem('WS-CLIENT-KEY'))
  useEffect(() => {
    // console.log('Last key', key);
    // sendJsonMessage('Voici un message')
    if(!user) sendJsonMessage({ type: 'logout', key})
    if(lastJsonMessage?.message) {
      if(lastJsonMessage.type==='authentification'){
        if(!lastJsonMessage.success){
          // logout().then()
        }
      }

      if(lastJsonMessage.type==='user_event') {
        setWsConnected(lastJsonMessage.message === 'ws connected')
        if(lastJsonMessage.key) {
          window.localStorage.setItem('WS-CLIENT-KEY', lastJsonMessage.key);
          setKey(lastJsonMessage.key);
          // console.log('lastJsonMessage.key', lastJsonMessage.key);
        }
      }
      if(lastJsonMessage.message === 'roles updated'){
        console.info('lastJsonMessage', lastJsonMessage)
        onRefreshRoles()
        updateCurrentUser(undefined).then()
      }
    }
    if(readyState === ReadyState.OPEN && user && !wsConnected && lastJsonMessage?.type==='user_event'){
      sendJsonMessage({ user, key })
    }
  }, [sendJsonMessage, readyState, lastJsonMessage, user, wsConnected, onRefreshRoles
    , updateCurrentUser, logout, key])
  const memorizedValue = useMemo(() => ({ wsConnected, sendJsonMessage, readyState, lastJsonMessage })
    , [lastJsonMessage, readyState, sendJsonMessage, wsConnected])

  return <WsContext.Provider value={memorizedValue}>{children}</WsContext.Provider>
}

export default WsProvider
