import { IInvoice } from '../types/invoice';

export function paramCase(str: string) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '');
}

export function snakeCase(str: string) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/[^a-z0-9_]/g, '');
}

export const firstLetterToUpperCase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const eachFirstLetterToUpperCase = (text: string) => text.split(' ').map(str => firstLetterToUpperCase(str)).join(' ')
