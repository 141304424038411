// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line import/named
import { getAccesses, getAllUsers, getRoles } from 'src/api/server/user';

// ----------------------------------------------------------------------

export const fetchUsersData = createAsyncThunk(
  'users/fetchUsersData',
  async ({ idUser }) => {
    const response = await getAllUsers(idUser)
    // eslint-disable-next-line no-restricted-syntax
    return response.data
  })

export const fetchAccessesData = createAsyncThunk(
  'users/fetchAccessesData',
  async ({ idUser }) => {
    const response = await getAccesses(idUser)
    // eslint-disable-next-line no-restricted-syntax
    return response.data
  })

export const fetchRolesData = createAsyncThunk(
  'users/fetchRolesData',
  async ({ idUser }) => {
    const response = await getRoles(idUser)
    // eslint-disable-next-line no-restricted-syntax
    return response.data
  })

const initialState = { allData: [], accesses: [], roles: [], isLoading: false, error: undefined }

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsers: (state, action) => {
      action.payload()
      state.allData = [];
    },
    updateUsers: (state, action) => {
      const { data } = action.payload;
      data.forEach(user => {
        const userFound = state.allData.find(usr => usr.id === user?.id);
        if (userFound) {
          Object.entries(user).forEach(([key, value]) => {
            userFound[key] = value;
          })
        }
      });
    },
    updateRoles: (state, action) => {
      const { data } = action.payload
      // console.log('updateRoles data', data)
      // console.log('updateRoles state', state)
      data.forEach(role => {
        const roleFound = state.roles.find(rl => rl.id === role?.id)
        if (roleFound) {
          console.log('updateRoles roleFound', roleFound)
          Object.entries(role).forEach(([key, value]) => {
            roleFound[key] = value
          })
        }
      })
    },
    updateAccesses: (state, action) => {
      const { data } = action.payload;
      data.forEach(access => {
        const accessFound = state.accesses.find(acc => acc.id === access?.id);
        if (accessFound) {
          Object.entries(access).forEach(([key, value]) => {
            accessFound[key] = value
          })
        }
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUsersData.pending, state => {
        if (!state.allData[0]) {
          state.isLoading = true
          state.error = undefined
        }
      })
      .addCase(fetchUsersData.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = undefined
        state.allData = action.payload
      })
      .addCase(fetchUsersData.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
      .addCase(fetchAccessesData.fulfilled, (state, action) => {
        state.accesses = action.payload
      })
      .addCase(fetchRolesData.fulfilled, (state, action) => {
        // console.info('fetchRolesData fulfilled payload', action.payload)
        // console.info('fetchRolesData state roles', state.roles)
        action.payload?.forEach((role) => {
          const roleFound = state.roles.find(rl => rl.id === role?.id)
          const date = (new Date()).toLocaleString()
          if (roleFound){
            Object.entries(role).forEach(entry => {
              const [key, value] = entry
              if (key !== 'importedAt' && key !== 'lastUpdatedDate') {
                roleFound[key] = value
              }
            })
            roleFound.lastUpdatedDate = (new Date()).toLocaleString()
          }
          else state.roles.push({...role, importedAt: date, lastUpdatedDate: date})
        })
        state.roles = action.payload
      })
  },
})

// Reducer
export default slice.reducer

// Actions
export const {
  resetUsers,
  updateUsers,
  updateRoles,
  updateAccesses,
} = slice.actions
