// eslint-disable-next-line import/no-extraneous-dependencies
import {configureStore} from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-extraneous-dependencies
import {Provider, useDispatch as useAppDispatch, useSelector as useAppSelector,} from 'react-redux'
// eslint-disable-next-line import/no-extraneous-dependencies
import {FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE} from 'redux-persist'
import {rootReducer} from './root-reducer'

// ----------------------------------------------------------------------

const App = () => useDispatch()
export const AppWrapper = () => {
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            }),
    })
    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <Provider store={store}>
            <App />
        </Provider>
    )
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

export const useSelector = useAppSelector

export const useDispatch = useAppDispatch
