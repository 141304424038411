import { useMemo } from 'react'
// routes
import { paths } from 'src/routes/paths'
// locales
import { useLocales } from 'src/locales'
// components
import SvgColor from 'src/components/svg-color'
// hooks
import { useAuthContext } from 'src/auth/hooks'

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
)

const ICONS = {
  user: icon('ic_user'),
  customer: icon('ic_customer'),
  order: icon('ic_order'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  dashboard: icon('ic_dashboard')
}

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const { user } = useAuthContext()
  const access = user?.access

  return useMemo(
    () => {
      const toReturn: any[] = []
      if(access){
        // console.log(access);
        const items: { title: any; path: any; icon: any; children: { title: string; path: any }[] }[] = []
        Object.entries(access).filter(([_key, value]:[string, any]) => value[0]).forEach(([key, value]:[string, any]) => {
          const children: { title: string; path: any }[] = []
          value.filter((val: any) => val.label)
            .forEach(({ name, label }:{name: string, label: string}) => {
              // @ts-ignore
              children.push({ title: t(label), path: `${paths.dashboard.root}/${key}/${name!=='root'
                && name || ''}` })
            })
          // @ts-ignore
          items.push({title: t(key), path: `${paths.dashboard.root}/${key}`, icon: ICONS[key], children})
        })
        toReturn.push(...[
          // OVERVIEW
          {
            subheader: t('overview'),
            items: [
              {
                title: t('app'),
                path: paths.dashboard.root,
                icon: ICONS.dashboard,
              }
            ],
          },

          // MANAGEMENT
          {
            subheader: t('management'),
            items
          }
        ])
      }
      return toReturn
    },
    [access, t]
  )
}
