import { useCallback, useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'src/redux/store'
import {
  updateUsers,
  updateRoles,
  updateAccesses,
  fetchUsersData,
  fetchRolesData,
  fetchAccessesData
} from 'src/redux/slice/user'

// ----------------------------------------------------------------------

export default function useUsers() {
  const dispatch = useDispatch()

  const users = useSelector((state) => state.users.allData)
  const accesses = useSelector((state) => state.users.accesses)
  const roles = useSelector((state) => state.users.roles)
  const isLoading = useSelector((state) => state.users.isLoading)
  const error = useSelector((state) => state.users.error)

  const onRefreshUsers = useCallback(
    (idUser) => {
      dispatch(fetchUsersData({ idUser }))
        .unwrap()
    },
    [dispatch],
  )

  const onRefreshAccesses = useCallback(
    (idUser) => {
      dispatch(fetchAccessesData({ idUser }))
        .unwrap()
        .then()
    },
    [dispatch],
  )


  const onRefreshRoles = useCallback(
    (idUser) => {
      dispatch(fetchRolesData({ idUser }))
        .unwrap()
        .then()
    },
    [dispatch],
  )

  const onInitUsers = useCallback(
    (idUser) => {
      console.log('onInitUsers idUser', idUser);
      if(idUser){
        onRefreshUsers(idUser)
        onRefreshRoles(idUser)
        onRefreshAccesses(idUser)
      }
    },
    [onRefreshAccesses, onRefreshRoles, onRefreshUsers]
  )

  useEffect(() => {
    onInitUsers()
  }, [onInitUsers])

  const onUpdateUsers = useCallback(
    (data) => {
      dispatch(updateUsers({ data }));
    },
    [dispatch]
  )

  const onUpdateRoles = useCallback(
    (data) => {
      dispatch(updateRoles({ data }));
    },
    [dispatch]
  )

  const onUpdateAccesses = useCallback(
    (data) => {
      dispatch(updateAccesses({ data }));
    },
    [dispatch]
  )

  return {
    allUsers: users,
    accesses,
    roles,
    isLoading,
    error,
    //
    onRefreshUsers,
    onInitUsers,
    onUpdateUsers,
    onUpdateRoles,
    onUpdateAccesses,
    onRefreshAccesses,
    onRefreshRoles
  };
}
