// utils
import { NetsuiteInstanceProps, axiosServerData } from 'src/utils/axios';

// ----------------------------------------------------------------------

const param = {firstEndPoint: 'customer'} as NetsuiteInstanceProps

const initParam = () => {
  param.data = undefined
  param.endUrl = ''
}

const createCustomer = (idUser: string, data: any) => {
  initParam()
  param.data = data
  param.method = 'post'
  param.idUser = idUser
  param.lastEndPoint = 'root'
  return axiosServerData(param)
}
const getCustomersByPage = (idUser: string, page = 1) => {
  initParam()
  param.method = 'get'
  param.idUser = idUser
  param.endUrl = `/${page}?count=100`
  param.lastEndPoint = 'page'
  return axiosServerData(param)
}
const getOneCustomer = (idUser: string, id: string) => {
  initParam()
  param.method = 'get'
  param.idUser = idUser
  param.endUrl = `/${id}`
  param.lastEndPoint = 'root'
  return axiosServerData(param)
}

export { createCustomer, getCustomersByPage, getOneCustomer };
