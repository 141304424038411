import React from 'react'
import {Elements} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

// Make sure to call `loadStripe` outside a component’s render to avoid
// recreating the `Stripe` object on every render.
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY || ''
const stripePromise = loadStripe(PUBLIC_KEY)

export function  StripeProvider({ children } : {children: React.ReactNode}) {
  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
}
