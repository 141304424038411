import {createSlice} from '@reduxjs/toolkit'

// ----------------------------------------------------------------------

const initialState = {
  activeStep: 0,
  items: [],
  subTotal: 0,
  total: 0,
  discount: 0,
  tax: 0,
  totalItems: 0,
  payment: {total: 0},
  customerSelected: {},
  invoiceSelected: {},
  printInvoice: false
}

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    resetItems: (state) => {
      Object.entries(initialState).forEach(([key, value]) => {
        state[key] = value
      })
    },
    selectInvoice: (state, action) => {
      const {payload} = action
      if(payload) Object.entries(payload).forEach(([key, value]) => { state[key] = value })
      state.customerSelected = payload.customer
      state.invoiceSelected = payload
    },
    selectCustomer: (state, action) => {
      const {payload} = action
      state.customerSelected = payload
    },
    updateItems: (state, action) => {
      const {payload} = action
      payload.forEach(product => {
        const itemFound = state.items.find(item => item.id === product?.id)
        if (itemFound) {
          state.totalItems -= parseInt(itemFound.quantity, 10)
          state.total -= parseFloat(itemFound.price) * parseInt(itemFound.quantity, 10)
          state.subTotal -= parseFloat(itemFound.price) * parseInt(itemFound.quantity, 10)
          if (product?.available) itemFound.available = parseInt(product?.available, 10)
          if (product?.quantity) itemFound.quantity = parseInt(product?.quantity, 10)
          if (product?.price) itemFound.price = product?.price
          if (itemFound.quantity > itemFound.available) itemFound.quantity = itemFound.available
          state.total += parseFloat(itemFound.price) * parseInt(itemFound.quantity, 10)
          state.subTotal += parseFloat(itemFound.price) * parseInt(itemFound.quantity, 10)
          state.totalItems += itemFound.quantity
        }
        state.items = state.items.filter(item => item.quantity > 0)
      })
    },
    increaseQuantity: (state, action) => {
      const {payload} = action
      const itemFound = state.items.find(item => item.id === payload?.id)
      if (itemFound) {
        if (itemFound.available > itemFound.quantity) {
          itemFound.quantity += 1
          state.total += parseFloat(itemFound.price)
          state.subTotal += parseFloat(itemFound.price)
        }
      } else if (payload?.id && payload?.price) {
        state.items.push({...payload, quantity: 1})
        state.total += parseFloat(payload?.price)
        state.subTotal += parseFloat(payload?.price)
      }
      state.totalItems += 1
      state.items = state.items.filter(item => item.quantity > 0)
    },
    decreaseQuantity: (state, action) => {
      const {payload} = action
      const itemFound = state.items.find(item => item.id === payload?.id)
      if (itemFound) {
        if (itemFound.quantity <= 1) state.items = state.items.filter(item => item.id !== payload?.id)
        else itemFound.quantity -= 1
        state.total -= parseFloat(itemFound.price)
        state.subTotal -= parseFloat(itemFound.price)
        state.totalItems -= 1
      }
      state.items = state.items.filter(item => item.quantity > 0)
    },
    updatePayment: (state, action) => {
      const {payload} = action
      const {value, name, reset} = payload
      if (!Number.isNaN(Number(value))) {
        if (reset) state.payment = {total: Number(value), [name]: value}
        else {
          let total = Number(value)
          Object.entries(state.payment).forEach(entry => {
            const [key, val] = entry
            if (key !== 'total' && key !== name) total += Number(val)
          })
          state.payment = {...state.payment, [name]: value, total}
        }
      }
    },
    nextStep: (state) => {
      if (state.activeStep < 2) state.activeStep += 1
    },
    backStep: (state) => {
      if (state.activeStep > 0) state.activeStep -= 1
    },
    firstStep: (state) => {
      state.activeStep = 0
    },
    updatePrintInvoice: (state, action) => {
      state.printInvoice = action.payload
    }
  }
})

// Reducer
export default slice.reducer

// Actions
export const {
  resetItems,
  selectInvoice,
  selectCustomer,
  updateItems,
  increaseQuantity,
  decreaseQuantity,
  nextStep,
  backStep,
  firstStep,
  updatePayment,
  updatePrintInvoice
} = slice.actions
