import { useEffect, useState, useCallback } from 'react'
// @mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// components
import Stepper, { Step } from 'src/components/stepper';
import Select from 'src/components/select';
// auth
import { useAuthContext } from 'src/auth/hooks';
// locales
import { useLocales } from 'src/locales';
// utils
import { firstLetterToUpperCase } from 'src/utils/change-case';
// types
import { CashRegister } from "src/types/cashRegister";
import { IConfigItem, IPaymentReaderItem, IPaymentMethodItem, IUserItem } from 'src/types/user';
// api
import { createCashRegister, loginCashRegister } from 'src/api/server/user';
// styles
import { firstBoxStyle } from './style';

const StartLayout = ({ children }: any) => {
  const { user, cashRegisterLogin } = useAuthContext();
  const { t } = useLocales();
  const [cashRegisterIdSelected, setCashRegisterIdSelected] = useState('' as string | string[])
  const [newCashRegister, setNewCashRegister] = useState({name : ''} as CashRegister)
  const [configIdSelected, setConfigIdSelected] = useState('' as string | string[])
  const [paymentReaders, setPaymentReaders] = useState([] as IPaymentReaderItem[])
  const [paymentReaderIdSelected, setPaymentReaderIdSelected] = useState('' as string | string[])
  const [users, setUsers] = useState([] as IUserItem[])
  const [userIdsSelected, setUserIdsSelected] = useState([] as string | string[])
  const transToUp = (str: string) => t(firstLetterToUpperCase(str));

  const cashRegisters = user?.cashRegisters || []

  const configs = user?.configs || []

  useEffect(() => {
    if(cashRegisterIdSelected) {
      const cashRegisterSelected = user?.cashRegisters.find((cashRegister: CashRegister) => cashRegister.id === cashRegisterIdSelected)
      const configSelected = user?.configs.find((config: IConfigItem) => config.paymentMethods
        .find(pm => pm.paymentReaders.find(pr => pr.id === cashRegisterSelected.paymentReader.id ||
          pr.readerId === cashRegisterSelected.paymentReader.readerId)))
      setConfigIdSelected(configSelected?.id)
      setPaymentReaderIdSelected(cashRegisterSelected?.paymentReader)
      setUserIdsSelected(cashRegisterSelected.users.map((usr: IUserItem) => usr.id))
    }
  }, [cashRegisterIdSelected, user?.cashRegisters, user?.configs])

  useEffect(() => {
    // console.log('configIdSelected', configIdSelected)
    const configSelected = user?.configs.find((config: IConfigItem) => config.id === configIdSelected)
    setPaymentReaders(configSelected?.paymentMethods?.reduce((prev: IPaymentReaderItem[], curr:
      IPaymentMethodItem) => prev.concat(curr.paymentReaders), []) || [])
    // console.log('paymentReaderIdSelected', paymentReaderIdSelected)
    // console.log('userIdsSelected', userIdsSelected)
    setUsers(configSelected?.users || [])
  }, [configIdSelected, paymentReaderIdSelected, user?.configs, userIdsSelected])

  const initialSteps = [
    {label: transToUp('select_or_create_cash_register')}

    , {label: transToUp('select_config_of_cash_register'), back: () => true}

    , {label: transToUp('select_payment_reader_of_cash_register'), back: () => true}

    , {label: transToUp('select_users_of_cash_register'), back: () => true}

    , {label: transToUp('confirm_cash_register'), back: () => true
      , next: () => {
        setActiveStep(steps[0])
        return true
      }
    }
  ] as Step[]

  const [steps, setSteps] = useState(initialSteps)
  const [activeStep, setActiveStep] = useState({ ...steps[0], id: 0 })

  // Change an active step
  const changeActiveStep = useCallback((id: number) => {
    setSteps(steps.map((step, idx) => ({...step, isActive: id === idx, id: idx})))
  }, [steps])
  useEffect(() => {
    if(!activeStep.isActive) changeActiveStep(activeStep.id)
    const stepFound = steps.find(step => step.isActive) || steps[0]
    stepFound.id = steps.indexOf(stepFound)
    setActiveStep(stepFound)
  }, [activeStep, changeActiveStep, setActiveStep, steps])
  // Complete step
  const completeStep = (id: number) => {
    const stepFound = steps.find((_step, idx) => id === idx)
    if(stepFound) stepFound.isCompleted = true
    setSteps(steps)
  }
  // Set step skipped
  const setStepSkipped = (id: number) => {
    const stepFound = steps.find((_step, idx) => id === idx)
    if(stepFound) stepFound.isSkipped = true
    setSteps(steps)
  }
  // Next step
  const handleNext = async () => {
    const next = activeStep === steps[0] && (newCashRegister.name && !cashRegisters.find(
      (register: CashRegister) => register.name === newCashRegister.name) || cashRegisterIdSelected) ||
      activeStep === steps[1] && configIdSelected || activeStep === steps[2] && paymentReaderIdSelected ||
      activeStep === steps[3] && userIdsSelected[0]
    if (activeStep === steps[4]){
      if(newCashRegister.name){
        newCashRegister.paymentReader = paymentReaderIdSelected as string
        newCashRegister.users = users.filter(usr => userIdsSelected.includes(usr.id)).map(usr => usr.id)
        console.log('newCashRegister', newCashRegister)
        const { data } = await createCashRegister(user?.id, newCashRegister)
        console.log('cash register created data', data)
        if(data?.id){
          setCashRegisterIdSelected(data.id)
        }
      }
      if(cashRegisterIdSelected){
        await cashRegisterLogin(cashRegisterIdSelected as string)
      }
    }
    if (next) {
      completeStep(activeStep.id)
      changeActiveStep(activeStep.id + 1)
    }
  }
  // Back step
  const handleBack = () => {
    if (activeStep.back?.()) changeActiveStep(activeStep.id - 1)
  }
  // Skip step
  const handleSkip = () => {
    // If the step is not optional, we can't skip it.
    if (!activeStep.isOptional)
      throw new Error(t(firstLetterToUpperCase("you_cant_skip_a_step_that_is_not_optional.")))
    setStepSkipped(activeStep.id)
    changeActiveStep(activeStep.id + 1)
  }
  // First box
  const firstBox = <Box sx={firstBoxStyle.first}>
    {
      activeStep === steps[0] &&
      <>
        <Typography>Select a cash register</Typography>
        <Select value={cashRegisterIdSelected} setValue={setCashRegisterIdSelected} label='Cash Register' items={cashRegisters
          .map((register: CashRegister) => ({value: register.id, label: register.name}))}/>
        {/*
        <Typography>Create a new cash register</Typography>
        <input style={firstBoxStyle.second} type="text" placeholder={transToUp("enter_name_of_new_cash_register")}
               value={newCashRegister.name} disabled={!!cashRegisterIdSelected}
               onChange={(e) => setNewCashRegister({...newCashRegister, name: e.target.value})}
        />
        */}
      </>
      || activeStep === steps[1] &&
      <>
        <h1>{cashRegisterIdSelected && cashRegisters.find((register: CashRegister) => register.id ===
          cashRegisterIdSelected)?.name || newCashRegister.name}</h1>
        <Typography>Select a config</Typography>
        <Select value={configIdSelected} setValue={setConfigIdSelected} disabled={!!cashRegisterIdSelected}
                label='Config' items={configs.map((config: IConfigItem) => ({value: config.id
          , label: config.credential.accountNetsuite}))}
        />
      </>
      || activeStep === steps[2] &&
      <>
        <Typography>Select a payment reader</Typography>
        <Select value={paymentReaderIdSelected} setValue={setPaymentReaderIdSelected} disabled={!!cashRegisterIdSelected}
                label='Payment reader' items={paymentReaders.map((reader: IPaymentReaderItem) => (
                  {value: reader.id, label: reader.name}))}
        />
      </>
      || activeStep === steps[3] &&
      <>
        <Typography>Select the users</Typography>
        <Select value={userIdsSelected} setValue={setUserIdsSelected} disabled={!!cashRegisterIdSelected}  label='Users'
                multiple items={users.map((usr: IUserItem) => ({value: usr.id, label: usr.displayName}))}
        />
      </>
      || activeStep === steps[4] &&
      <>
        <Typography>Confirm the cash register</Typography>
        {
          cashRegisterIdSelected && <h1> Cash Register selected : {
            cashRegisters.find((register: CashRegister) => register.id === cashRegisterIdSelected)?.name
          } </h1> || newCashRegister.name && <h1>New Cash Register : {newCashRegister.name} </h1>
        }
        <h3> Config : {configs.find((config: IConfigItem) => config.id === configIdSelected)?.credential.accountNetsuite} </h3>
        <h3> Payment reader : {paymentReaders.find((reader: IPaymentReaderItem) => reader.id === paymentReaderIdSelected)?.name} </h3>
        <h3> Users : {typeof userIdsSelected !== 'string' && userIdsSelected.map((id: string) => users.find(
          (usr: IUserItem) => usr.id === id)?.displayName).join(', ') || userIdsSelected} </h3>
      </>
    }
  </Box>
  // Next disabled
  const nextDisabled = activeStep === steps[0] && (!cashRegisterIdSelected && !newCashRegister.name) ||
    activeStep === steps[1] && !configIdSelected || activeStep === steps[2] && !paymentReaderIdSelected ||
    activeStep === steps[3] && !userIdsSelected[0]

  return (
    <>
      {(!user || user.role === 'Admin' || user.cashRegister) && children ||
        <Stepper activeStep={activeStep} steps={steps} handleNext={handleNext} handleBack={handleBack}
                 handleSkip={handleSkip} firstBox={firstBox} nextDisabled={nextDisabled}
        />}
    </>
  )
}

export default StartLayout
