// utils
import { axiosServerData, NetsuiteInstanceProps } from 'src/utils/axios'

// ----------------------------------------------------------------------

const param = {firstEndPoint: 'account'} as NetsuiteInstanceProps

const initParam = () => {
  param.data = undefined
  param.endUrl = ''
}

export const verifyAccount = (idUser: string) => {
  initParam()
  param.method = 'get'
  param.idUser = idUser
  param.lastEndPoint = 'verify'
  return axiosServerData(param)
}
