import { Theme } from '@mui/system/createTheme/createTheme';

// ----------------------------------------------------------------------

export const SUPPLY_STATUS_OPTIONS = [
  { value: 'pending_approval', label: 'pending_approval' },
  { value: 'pending_receive', label: 'pending_receive' },
  { value: 'partially_received', label: 'partially_received' },
  { value: 'received', label: 'received' },
  { value: 'closed', label: 'closed' },
];

export const SUPPLY_HISTORY_COLORS = {
  "supply_created": (theme: Theme) => theme.palette.grey[500],
  "supply_confirmed": (theme: Theme) => theme.palette.warning.main,
  "supply_closed": (theme: Theme) => theme.palette.error.main,
  "supply_received": (theme: Theme) => theme.palette.success.main,
  "supply_partially_received": (theme: Theme) => theme.palette.info.dark,
};

export const SUPPLY_HISTORY_ICONS = {
  supply_received: "mdi:package-variant",
  supply_partially_received: "mdi:package-variant-closed",
  supply_closed: "mdi-package-variant-remove",
  supply_created: "mdi-file-document-edit-outline",
  supply_confirmed: "mdi:checkbox-marked-circle-outline"
};

export const STATUS_EVENT = {
  pending_receive: "supply_confirmed",
  partially_received: "supply_partially_received",
  received: "supply_received",
  closed: "supply_closed"
}

export const HIGHLIGHT_STYLES = {
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  transform: "scale(0.98)",
  backgroundColor: "rgba(0, 120, 103, 0.04)",
  transition: "box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease"
};

export const QUANTITY_OPTIONS = [
  { value: 'complete', label: 'complete' },
  { value: 'partial', label: 'partial' },
  { value: 'surplus', label: 'surplus' },
];

export const STATUS_COLOR = {
  pending_approval: {
    backgroundColor: (theme: Theme) => theme.palette.grey[300],
    color: (theme: Theme) => theme.palette.grey[600],
  },
  pending_receive: {
    backgroundColor: (theme: Theme) => theme.palette.warning.lighter,
    color: (theme: Theme) => theme.palette.warning.dark,
  },
  partially_received: {
    backgroundColor: (theme: Theme) => theme.palette.info.lighter,
    color: (theme: Theme) => theme.palette.info.dark,
  },
  received: {
    backgroundColor: (theme: Theme) => theme.palette.success.lighter,
    color: (theme: Theme) => theme.palette.success.dark,
  },
  closed: {
    backgroundColor: (theme: Theme) => theme.palette.error.lighter,
    color: (theme: Theme) => theme.palette.error.dark,
  }
}

const products = [
    {
      id: "1",
      name: "Article SAV PL",
      sku: "Article SAV PL",
      coverUrl: "",
      category: "",
      available: 980,
      supplyId: "1",
      productId: "19718",
      quantity: 26,
      quantityReceived: 0,
      addDate: new Date("2024-03-25T16:13:43.290Z"),
    },
    {
      id: "2",
      name: "2-Stone Comet Essential Ring",
      sku: "FPLCL-R05-Y-61FH-6",
      coverUrl: "",
      category: "",
      available: 0,
      supplyId: "1",
      productId: "30000",
      quantity: 89,
      quantityReceived: 0,
      addDate: new Date("2024-03-25T16:13:43.290Z"),
    }
  ]
const totalQuantity = products.reduce((acc, cur) => acc + cur.quantity, 0);

export const DEFAULT_SUPPLIES = [
  {
    id: "1",
    name: "Supply#1",
    totalQuantity,
    description: "La première demande d'approvisionnement",
    createdAt: new Date("23 May 2024"),
    status: "pending_approval",
    products,
    histories: [
      {id: "1", event: "supply_created", date: new Date("23 May 2024")},
    ]
  },
  {
    id: "2",
    name: "Supply#2",
    totalQuantity,
    description: "Une deuxième demande d'approvisionnement",
    createdAt: new Date("14 June 2024"),
    status: "pending_receive",
    products,
    histories: [
      {id: "1", event: "supply_created", date: new Date("14 June 2024")},
      {id: "2", event: "supply_confirmed", date: new Date("16 June 2024")}
    ]
  },
  {
    id: "3",
    name: "Supply#3",
    totalQuantity,
    description: "La première demande d'approvisionnement",
    createdAt: new Date("23 May 2024"),
    status: "partially_received",
    products: products.map((p, i) => {
      if(i === 0) p.quantityReceived = 16;
      return p
    }),
    histories: [
      {id: "1", event: "supply_created", date: new Date("23 May 2024")},
      {id: "2", event: "supply_confirmed", date: new Date("06 June 2024")},
      {id: "3", event: "supply_partially_received", date: new Date("26 June 2024")
        , receiveDetail: [{productId: "1", name: "Article SAV PL", quantity: 16}]},
      {id: "4", event: "supply_partially_received", date: new Date("28 June 2024")
        , receiveDetail: [{productId: "1", name: "Article SAV PL", quantity: 4}
          , {productId: "2", name: "2-Stone Comet Essential Ring", quantity: 27}]}
    ]
  },
  {
    id: "4",
    name: "Supply#4",
    totalQuantity,
    description: "Une deuxième demande d'approvisionnement",
    createdAt: new Date("14 June 2024"),
    status: "received",
    products,
    histories: [
      {id: "1", event: "supply_created", date: new Date("14 June 2024")},
      {id: "2", event: "supply_confirmed", date: new Date("15 June 2024")},
      {id: "3", event: "supply_received", date: new Date("20 June 2024")
        , receiveDetail: [
        { productId: "1", name: "Article SAV PL", quantity: 26 }
          , { productId: "2", name: "2-Stone Comet Essential Ring", quantity: 89 }
        ]
      }
    ]
  },
  {
    id: "5",
    name: "Supply#5",
    totalQuantity,
    description: "Une deuxième demande d'approvisionnement",
    createdAt: new Date("14 June 2024"),
    status: "closed",
    products,
    histories: [
      {id: "1", event: "supply_created", date: new Date("14 June 2024")},
      {id: "2", event: "supply_confirmed", date: new Date("14 June 2024")},
      {id: "3", event: "supply_received", date: new Date("25 June 2024")
        , receiveDetail: [
          { productId: "1", name: "Article SAV PL", quantity: 26}
          , { productId: "2", name: "2-Stone Comet Essential Ring", quantity: 89}
        ]
      },
      {id: "4", event: "supply_closed", date: new Date("26 June 2024")}
    ]
  }
]
