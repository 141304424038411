// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux'
// eslint-disable-next-line import/no-extraneous-dependencies
import { persistReducer } from 'redux-persist'
// eslint-disable-next-line import/no-extraneous-dependencies
import storage from 'redux-persist/lib/storage'
// slices
import productsReducer from './slice/product'
import checkoutReducer from './slice/checkout'
import customerReducer from './slice/customer'
import invoiceReducer from './slice/invoice'
import userReducer from './slice/user'
import supplyReducer from './slice/supply'

// ----------------------------------------------------------------------

const productsPersistConfig = {
  key: 'products',
  storage,
  keyPrefix: 'redux-',
}

const checkoutPersistConfig = {
  key: 'checkout',
  storage,
  keyPrefix: 'redux-',
}

const customersPersistConfig = {
  key: 'customers',
  storage,
  keyPrefix: 'redux-',
}

const invoicesPersistConfig = {
  key: 'invoices',
  storage,
  keyPrefix: 'redux-',
}

const usersPersistConfig = {
  key: 'users',
  storage,
  keyPrefix: 'redux-',
}

const suppliesPersistConfig = {
  key: 'supplies',
  storage,
  keyPrefix: 'redux-',
}

export const rootReducer = combineReducers({
  products: persistReducer(productsPersistConfig, productsReducer),
  checkout: persistReducer(checkoutPersistConfig, checkoutReducer),
  customers: persistReducer(customersPersistConfig, customerReducer),
  invoices: persistReducer(invoicesPersistConfig, invoiceReducer),
  users: persistReducer(usersPersistConfig, userReducer),
  supplies: persistReducer(suppliesPersistConfig, supplyReducer),
})
