export const generateID = (size: number) => {
  let id = ''
  do{
    id+= Math.random().toString(36).substring(2, (size - id.length) + 2)
  }
  while (id.length < size)
  return id
}

export const generateNumber = (size: number) => Math.floor(Math.random() * (10 ** size))
