// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API
export const ASSETS_API = process.env.REACT_APP_ASSETS_API
export const NETSUITE_API = {
  host : process.env.REACT_APP_NETSUITE_HOST_API
}

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
}

export const SES_API = {
  credentials: {
    accessKeyId: process.env.REACT_APP_KEY_ID,
    secretAccessKey: process.env.REACT_APP_KEY_SECRET
  },
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
}

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = '/dashboard'
