import React, { useEffect, useReducer, useCallback, useMemo } from "react"
import { Auth } from "@aws-amplify/auth"
// config
import { AMPLIFY_API } from "src/config-global"
// api
import { verifyAccount } from 'src/api/server/account'
import { getProfile, loginCashRegister, updateProfile, verifyCashRegister } from 'src/api/server/user'
// routes
import { paths } from 'src/routes/paths'
//
import { AuthContext } from "./auth-context"
import { ActionMapType, AuthStateType, AuthUserType, RegisterParam } from "../../types"

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = "INITIAL",
  LOGOUT = "LOGOUT",
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
}

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true
}

const reducer = (state: AuthStateType, action: Action) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
}

// ----------------------------------------------------------------------

Auth.configure(AMPLIFY_API);

type Props = {
  children: React.ReactNode
}

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateCurrentUser = useCallback( async (data: any) => {
    if(!data) data = await getProfile(state.user?.id)
    dispatch({ type: Types.INITIAL, payload: { user: data?.data || data }})
  }, [state.user?.id])

  const updateUser = useCallback(async (data: any) => {
    try {
      const fields = {
        given_name: 'firstName',
        family_name: 'lastName',
        phone_number: 'phone'
      }
      const updatedAttributes = {} as any
      Object.entries(fields).forEach(([field, value]) => {
        updatedAttributes[field] = data[value]
      })
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, updatedAttributes)
      console.log('User attributes updated successfully', user)
      const updated = await updateProfile(user?.username, data)
      console.info('updated', updated)
      await updateCurrentUser(updated.data)
    }
    catch (error) {
      console.error('Error updating user attributes', error);
    }
  }, [updateCurrentUser])

  const initialize = useCallback(async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      console.log('currentUser', currentUser)
      if (currentUser) {
        const profile = await getProfile(currentUser.attributes.sub)
        const user = profile.data
        console.log('AuthProvider user', user)
        try {
          const cashRegister = await verifyCashRegister(currentUser.attributes.sub)
          console.log('AuthProvider cashRegister', cashRegister)
          user.cashRegister = cashRegister.data
        }
        catch (error) {
          console.error('cashRegister Error', error)
        }
        user.configSelected = user.configSelected || user.configs[0]
        dispatch({
          type: Types.INITIAL,
          payload: { user },
        })
      }
      else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        })
      }
    }
    catch (error) {
      await Auth.signOut()
      console.error(error)
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      })
    }
  }, [])

  useEffect(() => {
    initialize().then()
  }, [initialize])

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    await Auth.signIn(email, password)
    await initialize()
  }, [initialize])

  const cashRegisterLogin = useCallback(async (cashRegisterId: string) => {
    const crl = await loginCashRegister(state?.user?.id, cashRegisterId)
    console.log('cashRegisterLogin', crl)
    window.localStorage.setItem('CASH-REGISTER-TOKEN', crl.cashRegister.token)
    crl.cashRegister.token = undefined
    dispatch({
      type: Types.INITIAL,
      payload: {
        user: {...state.user || {}, ...crl || {}},
      },
    })
  }, [state.user])

  // REGISTER
  const register = useCallback(
    async (data: RegisterParam) => {
      const {email, password, lastName, firstName, netsuiteAccount,
        phoneNumber} = data
      const verified = await verifyAccount(netsuiteAccount)
      console.info('account verified', verified)
      if(verified) {
        const signUp = await Auth.signUp({
          username: email,
          /*  username: `${firstName}_${lastName}`.toLowerCase(), */
          password,
          attributes: {
            email,
            given_name: firstName,
            family_name: lastName,
            phone_number: phoneNumber && (!phoneNumber?.startsWith('+') && '+' || '') + phoneNumber || undefined,
          },
        })
        console.info('signUp', signUp)
        return signUp
      }
      throw new Error('This netsuite account is not registered')
    },
    []
  );

  // CONFIRM REGISTER
  const confirmRegister = useCallback(async (email: string, code: string) => {
    await Auth.confirmSignUp(email, code);
  }, []);

  // RESEND CODE REGISTER
  const resendCodeRegister = useCallback(async (email: string) => {
    await Auth.resendSignUp(email);
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await Auth.signOut();
    dispatch({
      type: Types.LOGOUT,
    });
    // window.location.replace(paths.auth.amplify.login)
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: string) => {
    await Auth.forgotPassword(email);
  }, []);

  // NEW PASSWORD
  const newPassword = useCallback(
    async (email: string, code: string, password: string) => {
      await Auth.forgotPasswordSubmit(email, code, password);
    },
    []
  )

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? "authenticated" : "unauthenticated";

  const status = state.loading ? "loading" : checkAuthenticated;

  // SELECT CONFIG
  const onSelectConfig = useCallback(async (configId: string) => {
    const { user } = state

    if(user){
      console.info('onSelectConfig user', user)

      user.configSelected = user.configs.find((config: any) => config.id === configId)

      dispatch({
        type: Types.INITIAL,
        payload: { user },
      });
    }
  }, [state])

  const memorizedValue = useMemo(
    () => ({
      user: state.user,
      method: "amplify",
      loading: status === "loading",
      authenticated: status === "authenticated",
      unauthenticated: status === "unauthenticated",
      //
      login,
      logout,
      register,
      updateUser,
      newPassword,
      onSelectConfig,
      forgotPassword,
      confirmRegister,
      updateCurrentUser,
      resendCodeRegister,
      cashRegisterLogin
    }),
    [state.user, status, login, logout, register, updateUser, newPassword, forgotPassword
      , confirmRegister, updateCurrentUser, resendCodeRegister, onSelectConfig, cashRegisterLogin]
  )

  return (
    <AuthContext.Provider value={memorizedValue}>
      {children}
    </AuthContext.Provider>
  )
}
