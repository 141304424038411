// i18n
import 'src/locales/i18n'

// scrollbar
import 'simplebar-react/dist/simplebar.min.css'

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css'

// editor
import 'react-quill/dist/quill.snow.css'

// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css'

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections'
// theme
import ThemeProvider from 'src/theme'
// locales
import { LocalizationProvider } from 'src/locales'
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top'
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// auth
// import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt'
// import { AuthProvider, AuthConsumer } from 'src/auth/context/auth0'
import { AuthProvider, AuthConsumer } from 'src/auth/context/amplify'
// import { AuthProvider, AuthConsumer } from 'src/auth/context/firebase'
// Layout
import StartLayout from 'src/layouts/start'
// redux
import ReduxProvider from './redux/redux-provider';
import WsProvider from './websocket/ws-provider';
// stripe
import { StripeProvider } from './stripe'

// ----------------------------------------------------------------------

export default function App() {
  /*
  const charAt = `

  ░░░    ░░░
  ▒▒▒▒  ▒▒▒▒
  ▒▒ ▒▒▒▒ ▒▒
  ▓▓  ▓▓  ▓▓
  ██      ██

  `

  console.info(`%c${charAt}`, 'color: #5BE49B')
  */

  useScrollToTop();

  return (
    <AuthProvider>
      <ReduxProvider>
        <LocalizationProvider>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SnackbarProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <AuthConsumer>
                    <StripeProvider>
                      <WsProvider>
                        <StartLayout>
                          <Router />
                        </StartLayout>
                      </WsProvider>
                    </StripeProvider>
                  </AuthConsumer>
                </SnackbarProvider>
              </MotionLazy>
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </ReduxProvider>
    </AuthProvider>
  );
}
