// eslint-disable-next-line import/no-extraneous-dependencies
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllInvoices, getOneInvoice } from 'src/api/server/invoice';
import  { generateID, generateNumber } from 'src/utils/random';

// ----------------------------------------------------------------------

export const fetchInvoicesData = createAsyncThunk(
  'invoices/fetchInvoicesData',
  async ({ idUser, page, addData }) => {
    const date = new Date();
    date.setDate(1);
    date.setHours(0, 0);
    const invoices = await getAllInvoices({ idUser, page, lastModified: date.toDateString() });
    console.info('fetchInvoicesData invoices', invoices);
    // eslint-disable-next-line no-restricted-syntax
    for(const invoice of invoices?.results||[]){
      // eslint-disable-next-line no-await-in-loop
      await addData(invoice)
    }
    return invoices;
  })

export const fetchOneInvoice = createAsyncThunk(
  'invoices/fetchOneInvoice',
  async ({ idUser, id, addData }) => {
    const invoice = await getOneInvoice(idUser, id)
    console.info('fetchOneInvoice invoice', invoice)
    addData(invoice)
    return invoice
  })

const initialState = { allData: [], drafts: [], syncs: [], isLoading: false, error: undefined, nextPage: 1 }

const updInvoice = (state, invoice) => {
  const invoiceFound = state.allData.find(x => x?.id === invoice.id)
  if (!invoiceFound) {
    const invToPush = {
      id : invoice.id,
      status : invoice.status,
      dueDate : invoice.dueDate,
      customer : invoice.customer,
      createdDate : invoice.createdDate,
      invoiceNumber : invoice.invoiceNumber,
      importedAt : (new Date()).toLocaleString(),
      lastUpdatedDate : (new Date()).toLocaleString()
    }
    const subTotal = invoice.items.reduce((prev, curr) => prev + (parseFloat(curr.price) *
      parseInt(curr.quantity, 10)), 0)
    invToPush.subTotal = subTotal
    invToPush.total = subTotal + (invoice.discount || 0) + (invoice.taxTotal || 0)
    state.allData.push(invToPush)
  }
  else {
    invoiceFound.lastUpdatedDate = new Date()
    invoiceFound.createdDate = invoice.createdDate
    invoiceFound.dueDate = invoice.dueDate
    invoiceFound.subTotal = invoice.items.reduce((prev, curr) => prev + (parseFloat(curr.price) *
      parseInt(curr.quantity, 10)), 0)
  }
}

const slice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    resetInvoices: (state, action) => {
      action.payload()
      Object.entries(initialState).forEach(([key, value]) => {
        state[key] = value;
      });
    },
    updateInvoices: (state, action) => {
      const { data, addData } = action.payload;
      data.forEach(invoice => {
        updInvoice(state, invoice)
        if(addData) addData(invoice)
      });
    },
    removeInvoice: (state, action) => {
      state.allData = state.allData.filter(data => data.id !== action.payload)
    },
    addDraft: (state, action) => {
      const {payload} = action
      do {
        payload.id = `draft_${generateID(18)}`
      }
      while (state.drafts.find(x => x?.id === payload?.id))
      payload.invoiceNumber = `Invoice #DRAFT${generateNumber(6)}`
      console.log('invoiceNumber', payload.invoiceNumber)
      payload.status = { value: 'draft', label: 'draft' }
      payload.createdDate = (new Date()).toString()
      payload.dueDate = (new Date()).toString()
      state.drafts.push(payload)
    },
    removeDraft: (state, action) => {
      state.drafts = state.drafts.filter(data => data.id !== action.payload)
    },
    updateDraft: (state, action) => {
      const { payload } = action
      const draftFound = state.drafts.find(x => x?.id === payload.id)
      if (draftFound) {
        Object.entries(payload).forEach(([key, value]) => {
          draftFound[key] = value
        })
      }
    },
    addSync: (state, action) => {
      const {payload} = action
      do {
        payload.id = `synchronising_${generateID(18)}`
      }
      while (state.syncs.find(x => x?.id === payload?.id))
      payload.invoiceNumber = `Invoice #SYNCHRONISING${generateNumber(6)}`
      payload.status = { value: 'synchronising', label: 'synchronising_with_erp' }
      payload.createdDate = (new Date()).toString()
      payload.dueDate = (new Date()).toString()
      console.log('addSync payload', payload)
      state.syncs.push(payload)
    },
    removeSync: (state, action) => {
      state.syncs = state.syncs.filter(data => data.id !== action.payload)
    },
    updateSync: (state, action) => {
      const { payload } = action
      const syncFound = state.syncs.find(x => x?.id === payload.id)
      if (syncFound) {
        Object.entries(payload).forEach(([key, value]) => {
          syncFound[key] = value
        })
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchInvoicesData.pending, state => {
        if (!state.allData[0]) {
          state.isLoading = true;
          state.error = undefined;
        }
      })
      .addCase(fetchInvoicesData.fulfilled, (state, action) => {
        const { results, nextPage } = action.payload;
        console.log('')
        state.isLoading = false;
        state.error = undefined;
        state.nextPage = nextPage;
        results?.forEach(invoice => updInvoice(state, invoice))
      })
      .addCase(fetchInvoicesData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchOneInvoice.fulfilled, (state, action) => {
        const data = action.payload;
        const dataFound = state.allData.find(x => x?.id === data.id);
        const invToPush = {
          id : data.id,
          status : data.status,
          dueDate : data.dueDate,
          customer : data.customer,
          createdDate : data.createdDate,
          invoiceNumber : data.invoiceNumber
        }
        const subTotal = data.items.reduce((prev, curr) => prev + (parseFloat(curr.price) *
          parseInt(curr.quantity, 10)), 0);
        invToPush.subTotal = subTotal
        invToPush.total = subTotal + (data.discount || 0) + (data.taxTotal || 0)
        if (!dataFound) {
          invToPush.importedAt = (new Date()).toLocaleString()
          invToPush.lastUpdatedDate = (new Date()).toLocaleString()
          state.allData.push(invToPush)
        }
        else {
          Object.entries(invToPush).forEach(entry => {
            const [key, value] = entry;
            if (key !== 'importedAt' && key !== 'lastUpdatedDate') dataFound[key] = value;
          });
          dataFound.lastUpdatedDate = (new Date()).toLocaleString()
        }
      })
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetInvoices,
  updateInvoices,
  removeInvoice,
  addDraft,
  removeDraft,
  updateDraft,
  addSync,
  removeSync,
  updateSync
} = slice.actions
