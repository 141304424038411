import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import CompactLayout from 'src/layouts/compact';
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// AMPLIFY
const AmplifyLoginPage = lazy(() => import('src/pages/auth/amplify/login'))
const AmplifyRegisterPage = lazy(() => import('src/pages/auth/amplify/register'))
const AmplifyVerifyPage = lazy(() => import('src/pages/auth/amplify/verify'))
const AmplifyNewPasswordPage = lazy(() => import('src/pages/auth/amplify/new-password'))
const AmplifyCreatePasswordPage = lazy(() => import('src/pages/auth/amplify/create-password'))
const AmplifyForgotPasswordPage = lazy(() => import('src/pages/auth/amplify/forgot-password'))

// AUTH0
const Auth0LoginPage = lazy(() => import('src/pages/auth/auth0/login'))
const Auth0Callback = lazy(() => import('src/pages/auth/auth0/callback'))

// ----------------------------------------------------------------------

const authAmplify = {
  path: 'amplify',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <AmplifyLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title="Manage the job more effectively with Bae360°">
          <AmplifyRegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: 'verify', element: <AmplifyVerifyPage /> },
        { path: 'new-password', element: <AmplifyNewPasswordPage /> },
        { path: 'forgot-password', element: <AmplifyForgotPasswordPage /> },
        { path: 'create-password/:token/:email', element: <AmplifyCreatePasswordPage /> }
      ],
    },
  ],
}

const authAuth0 = {
  path: 'auth0',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <Auth0LoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'callback',
      element: <Auth0Callback />,
    },
  ],
}

export const authRoutes = [
  {
    path: 'auth',
    children: [authAmplify, authAuth0],
  },
];
