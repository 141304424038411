import { useMemo } from 'react';

// ----------------------------------------------------------------------
import useUsers from './hooks/use-users';

export function useGetUsers() {

  const { allUsers, accesses, roles, isLoading, error, onUpdateRoles, onUpdateAccesses,
    onRefreshUsers, onRefreshAccesses, onRefreshRoles, onInitUsers, onUpdateUsers } = useUsers();

  return useMemo(
    () => ({
      users: allUsers[0] && allUsers || [],
      usersLoading: isLoading,
      usersError: error,
      usersEmpty: !isLoading && !allUsers.length,
      accesses,
      roles,
      onUpdateRoles,
      onUpdateUsers,
      onUpdateAccesses,
      onRefreshUsers,
      onRefreshRoles,
      onRefreshAccesses,
      onInitUsers
    }),
    [allUsers, isLoading, error, accesses, roles, onUpdateRoles, onUpdateAccesses,
      onRefreshUsers, onRefreshRoles, onRefreshAccesses, onInitUsers, onUpdateUsers],
  );
}

// ----------------------------------------------------------------------
