// utils
import { axiosServerData, NetsuiteInstanceProps } from 'src/utils/axios'
// types
import { IInvoice } from 'src/types/invoice'

// ----------------------------------------------------------------------

const param = {firstEndPoint: 'invoice'} as NetsuiteInstanceProps

const initParam = () => {
  param.data = undefined
  param.endUrl = ''
}

const createInvoice = async (idUser: string, data: any) => {
  initParam()
  param.data = data
  param.method = 'post'
  param.idUser = idUser
  param.lastEndPoint = 'root'
  return await axiosServerData(param) as IInvoice
}

type gipProp = {
  page?: number
  count?: number
  lastModified?: string
  idUser: string
}
const getAllInvoices = ({ page, count, lastModified, idUser }: gipProp) => {
  initParam()
  param.method = 'get'
  param.idUser = idUser
  param.endUrl = `?page=${page || 1}&count=${count || 100}${lastModified && `&lastModified=${lastModified}` || ''}`
  param.lastEndPoint = 'root'
  return axiosServerData(param)
}
const getOneInvoice = (idUser: string, id: string) => {
  initParam()
  param.method = 'get'
  param.idUser = idUser
  param.endUrl = `/${id}`
  param.lastEndPoint = 'root'
  return axiosServerData(param)
}

export { createInvoice, getAllInvoices, getOneInvoice }
