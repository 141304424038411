import axios, { AxiosRequestConfig } from 'axios';
import bcrypt from 'bcryptjs-react';
// config
import { HOST_API, NETSUITE_API } from 'src/config-global';

// ----------------------------------------------------------------------
const salt = process.env.REACT_APP_SALT?.split('-').join('$');
const encrypt = (word: string) => {
  const date = new Date();
  const keyDate = `${date.getDate()}${date.getMonth()}${date.getFullYear()}${date.getSeconds()}`;
  return bcrypt.hashSync(word + keyDate, salt);
};
const axiosNewInstance = (baseURL?: string) => {
  const instance = axios.create({ baseURL });
  instance.interceptors.response.use(
    (res) => res,
    (error) => Promise.reject(error.response?.data || error.message || 'Something went wrong'),
  );
  return instance;
};
const axiosInstance = axiosNewInstance(HOST_API);
const serverEndPoints = (id?: string) => ({
  account: {
    verify: `/account/verify/${id}`,
  },
  stripe: {
    readers: `/stripe/${id}/readers`,
    payment_intent: `/stripe/${id}/create-payment-intent`,
    cancelAction : `/stripe/${id}/cancel-action`,
    processPayment : `/stripe/${id}/process-payment`,
    simulatePayment : (paymentIntentId: string) => `/stripe/${id}/simulate-payment/${paymentIntentId}`,
    capturePayment : (paymentIntentId: string) => `/stripe/${id}/capture-payment-intent/${paymentIntentId}`,
    confirmPayment : (paymentIntentId: string) => `/stripe/${id}/confirm-payment-intent/${paymentIntentId}`
  },
  user: {
    root: `/v1/admin/${id}/user`,
    cognito: `/v1/admin/user/cognito`,
    list: `/v1/user/${id}/user/list`,
    profile: `/v1/user/${id}/profile`,
    accesses: `/v1/user/${id}/accesses`,
    roles: `/v1/user/${id}/roles`,
    cashRegister: `/v1/user/${id}/cashRegister`,
    supply: `/v1/user/${id}/supply`,
  },
  product: {
    list: `/products/${id}`,
    page: `/products/${id}/page`,
    ids: `/products/${id}/ids`,
  },
  invoice: {
    root: `/invoice/${id}`,
    page: `/invoice/${id}/page`,
    ids: `/invoice/${id}/ids`,
  },
  customer: {
    root: `/customer/${id}`,
    page: `/customer/${id}/page`,
    ids: `/customer/${id}/ids`,
  }
});
export type NetsuiteInstanceProps = {
  method: 'post' | 'get' | 'patch'
  idUser?: string
  firstEndPoint: 'account' | 'user' | 'product' | 'invoice' | 'customer' | 'stripe'
  lastEndPoint: 'verify' | 'root' | 'cognito' | 'list' | 'page' | 'ids' | 'profile' |
    'accesses' | 'roles' | 'payment_intent' | 'readers' | 'cancelAction' | 'processPayment' |
    'capturePayment' | 'confirmPayment' | 'simulatePayment' | 'cashRegister' | 'supply'
  endUrl?: string
  data?: any
  endpointParam?: string | Object
}
export const axiosServerData = async (props: NetsuiteInstanceProps) => {
  const {
    method, idUser, firstEndPoint, lastEndPoint,
    endUrl, data, endpointParam
  } = props;
  const tokenEncrypted = encrypt(process.env.REACT_APP_TOKEN_BAE || '');
  // @ts-ignore
  const endpoint = serverEndPoints(idUser)[firstEndPoint][lastEndPoint]
  const finalEndPoint = typeof endpoint === 'function' ? endpoint(endpointParam) : endpoint
  const headers = {
    'x-pos360-token': tokenEncrypted
    , 'CASH-REGISTER-TOKEN': window.localStorage.getItem('CASH-REGISTER-TOKEN')
  };
  return (await axiosNewInstance(NETSUITE_API.host)[method](`${finalEndPoint}${endUrl}`,
    data || { headers }, data && { headers }))?.data;
};

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};

export default axios;
