import * as React from 'react'
// @mui
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
// types
import { StepperProps } from './type'

export default function HorizontalLinearStepper(
  {steps, activeStep, handleNext, handleBack, handleSkip, firstBox, nextDisabled}: StepperProps
) {

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep.id}>
        {steps.map(({ label, isOptional, isCompleted }) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {}
          if (isOptional) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            )
          }
          stepProps.completed = isCompleted
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep.id + 1}</Typography>
      {firstBox}
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          color="inherit"
          disabled={activeStep.id === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        {activeStep.isOptional && (
          <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
            Skip
          </Button>
        )}
        <Button disabled={nextDisabled || activeStep.nextDisabled} onClick={handleNext}>
          {activeStep.id === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>
    </Box>
  )
}
